/* Navbar */
.nbCustom {
  transition: background-color 0.3s ease;
}

.nbCustom.scrolled {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nbCustom.dark-theme {
  background-color: #333333;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
}

.nbCustom.initial {
  background-color: transparent !important;
  box-shadow: none !important;
}

/* Avatares */
.avatar-border {
  border: 1px solid #757575;
  padding: 4px;
  border-radius: 50%;
  display: inline-block;
}

/* Banner landing */
.bnLanding {
  position: relative;
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  overflow: hidden;
}

.bnContainer {
  z-index: 1;
}

.bnBackContent {
  position: relative;
  z-index: 2;
}

.bnBackImage {
  height: 155%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.85;
}

/* Banner Md Landing */
.bnMdLanding {
  position: relative;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  overflow: hidden;
}

.bnMdContainer {
  z-index: 1;
}

.bnMdBackContent {
  position: relative;
  z-index: 2;
}

.bnMdBackImage {
  height: 155%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
}

.fixedElement {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 22.5vh;
  z-index: 1;
  overflow: hidden;
}

/* Normal Landing */
.nmLanding {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  overflow: hidden;
}

.nmContainer {
  z-index: 1;
}

.nmBackContent {
  position: relative;
  z-index: 2;
}

.nmBackImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.75;
}

/* Contenido Lateral Derecho */
.sidebarTitles {
  padding-top: 270px;
  margin-top: -270px;
}
